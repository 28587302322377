import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.settings.apiUrl;
  userData$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private router: Router) {}

  login(url: string, body: any) {
    return this.http.post(this.apiUrl + url, body);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  setUserInfo(userInfo: any) {
    localStorage.setItem('isAdmin', userInfo.isAdmin);
    localStorage.setItem('userName', userInfo.username);
    localStorage.setItem('userId', userInfo.id);
  }

  getUserName() {
    return localStorage.getItem('userName');
  }

  isAdmin(): any {
    const isAdmin: any = localStorage.getItem('isAdmin');
    return JSON.parse(isAdmin);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }
}
